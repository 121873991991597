import {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout'
import { AuthHook } from '../hooks/authHooks'
import logo from '../assets/logo.svg'


export default function Nav() {
    const { logout } = useLogout()
    const {user} = AuthHook()
    const navigate = useNavigate()

    const handleLogout = () => {
        logout()
        setNavbar(false)
    } 
    const handleSignin = () => {
        navigate('/login')
        setNavbar(false)
    } 
    const [navbar, setNavbar] = useState(false);
  return (
    <nav className="w-full bg-white shadow sticky top-0 ">
    <div className=" justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <Link to='/'> <img src={logo}  alt='logo' className=''  /></Link>
                <div className="md:hidden">
                    <button
                        className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                        onClick={() => setNavbar(!navbar)}
                    >
                        {navbar ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        )}
                    </button>
                </div>
            </div>
        </div>
        <div>
            <div
                className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                    navbar ? "block" : "hidden"
                }`}
            >
                <ul className="items-center justify-center text-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                    <li className=' font-bold'> <Link to='/'>Home</Link></li>
                    <li className=''> <Link to='/uniquelink'>Dashboard</Link></li>
                    <li className=''><Link to='/profile'>Profile</Link></li>
                    <li className=''><Link to='/'>Premium</Link></li>
                    <li className=''><Link to='/create'>Create</Link></li>
                    {!user? 
                    <li className=' px-3 py-1  rounded-lg bg-sec text-center text-white' onClick={handleSignin}>Sign In</li> :
                    <button className='px-3 py-1 rounded-lg bg-sec text-white' onClick={handleLogout}>Log out</button>}
                    
                </ul>
            </div>
        </div>
    </div>
</nav>
  )
}
